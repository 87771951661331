import { Dispatch } from 'react';
import parsePhoneNumber from 'libphonenumber-js';
import { EditorState, ContentState, convertToRaw, Modifier, SelectionState } from 'draft-js';
import { MentionData } from '@draft-js-plugins/mention';
import { Tag } from 'react-tag-autocomplete';
import ReactGA from 'react-ga4';

import {
  US_NUMBER_FORMAT,
  COMMON_SOCIAL_PROVIDER_TYPE,
  CREATE_POST_TYPE,
  ALLOW_ONLY_NUMBERS_REGEX,
  ROUTE_TABS,
  CN_SOCIAL_MEDIA_STATUS,
  NEXT_LINE_SPLITTER,
  ADD_POST_CONTENT_MAX_TEXT_LENGTH,
  DL_STORE_DETAILS_CHECKFILEDS,
  USER_OWNERSHIP,
  GET_NUMBERS_ONLY_REGEX,
  socialMediaCallToActionCallLabels,
  URL_REGEX_WITH_OPTIONAL_PROTOCAL,
  PERSONA_FIELDS_FOR_CAPTION_GENERATION
} from 'utils/constants';
import { canReplyDateTime } from 'utils/helpers';
import { connectionApiTypes, loginReducerType, commonProps, advocacyLeaderboardApiTypes, scApiTypes, inboxApiTypes, settingsAIPlayBookReducerTypes, aiContentCreatorReducerTypes } from 'types';
import { accountSwitcherResponse, addPostSelectHashtagResponse, setCreatorAICaptions } from 'actions';
import { commonAccountSwitcher } from 'types/common';

export const TrackGoogleAnalyticsEvent = (category: string, eventName: string, label: string, data: any) => {
  // console.log("GA event:", category, ":", eventName, ":", label);
  const eventParams = {
    category,
    label,
    ...data
  };
  // Send GA4 Event
  ReactGA.event(eventName, eventParams);
  // console.log('reactGA data', eventName, eventParams);
};

const csvStringHandler = (stringValue: string) => {
  return CN_SOCIAL_MEDIA_STATUS.find((it) => it.name === stringValue)?.label;
};

export const connectionsLocListDownloadFormatter = (data: connectionApiTypes.IAccountConnectionGridRowList[]) => {
  return data.map((it) => ({
    'Account Id': it.account_id,
    'Account Name': it.account_name,
    'Facebook Status': csvStringHandler(it.facebook_connection_status),
    'X (Formerly Twitter) Status': csvStringHandler(it.twitter_connection_status),
    'Linkedin Status': csvStringHandler(it.linkedin_connection_status),
    'Google Status': csvStringHandler(it.google_connection_status),
    'Instagram Business Page Status': csvStringHandler(it.instagram_business_page_connection_status),
    'Yelp Status': csvStringHandler(it.yelp_connection_status)
  }));
};

export const analyticsLeaderBoardCSDownloadFormatter = (data: any) => {
  return data.map((it: { [key: string]: string | number }) => {
    const objLeaderBoardCSList: { [key: string]: string | number } = {
      account_id: it.account_id,
      account_name: it.account_name,
      facebook_likes: it.facebook_followers,
      facebook_likes_delta: it.facebook_followers_delta,
      ...it
    };
    delete objLeaderBoardCSList.facebook_followers;
    delete objLeaderBoardCSList.facebook_followers_delta;
    return objLeaderBoardCSList;
  });
};

export const advocacyLeaderBoardLocationsDownloadFormeter = (data: advocacyLeaderboardApiTypes.IAdvLBConsolidateAdvocacy[]) => {
  return data.map((it) => ({
    ...(it.location_name ? { Locations: it.location_name } : { Employees: it.employee_name }),
    'Assets Submitted': it.assets_submitted_count,
    'Advocacy Posts': it.advocacy_posts_count,
    Score: it.total_score,
    'Brand Rank': it.brand_ranking
  }));
};

export const getPercentage = (total: number, value: number) => {
  return Math.floor((value / total) * 100);
};

export const getReviewRepliedPercentage = (value: number) => {
  return Math.round(value * 100);
};

export const getFormattedNumber = (value: number) => {
  return !isNaN(value) ? new Intl.NumberFormat(US_NUMBER_FORMAT).format(value) : 0;
};

export const urlParser = (text: string) => {
  if (!text) return '';
  // eslint-disable-next-line
  const urlRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  const urlString = ` ${text.match(urlRegex)}`;
  return urlString !== ' null' ? urlString : '';
};

export const removeUrlFromText = (text: string) => {
  if (!text) return '';
  // eslint-disable-next-line
  const plainText = text.replace(
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
    ''
  );
  return plainText;
};

/** Get capitialized name */
export const capitalizeName = (firstName?: null | string, secondName?: null | string) => {
  const firstNameCapitalized = firstName ? firstName.charAt(0).toUpperCase() + firstName.slice(1) : '';
  const secondNameCapitalized = secondName ? secondName.charAt(0).toUpperCase() + secondName.slice(1) : '';
  return `${firstNameCapitalized}${secondNameCapitalized ? ` ${secondNameCapitalized}` : ''}`;
};

export const parseQueryString = (queryString: string) => {
  const urlParams = new URLSearchParams(queryString);
  return Object.fromEntries(urlParams);
};

export const parsePhoneNumbers = (contactNumber: string | null | undefined, type?: string) => {
  if (!contactNumber) return '';
  const parsedNumber = parsePhoneNumber(contactNumber, 'US');
  const phoneNumber = parsedNumber ? parsedNumber?.nationalNumber : type === ROUTE_TABS.TEAM_MANAGEMENT_LOGINS ? contactNumber : null;
  return `${phoneNumber}`;
};

export const formatPhoneNumber = (contactNumber: any) => {
  const plainNum = contactNumber.replace(ALLOW_ONLY_NUMBERS_REGEX, '');
  const contactNumb = plainNum.substr(-10, 10);
  const phoneNumber = parsePhoneNumber(contactNumb, 'US');
  if (phoneNumber) {
    return phoneNumber?.formatNational();
  } else return contactNumb;
};

export const canReply = (listItem: inboxApiTypes.IInboxListSocialContents | scApiTypes.ISCList) => {
  const isOlderThanOneDay = canReplyDateTime(listItem.originally_posted_at);
  if (
    listItem.type !== CREATE_POST_TYPE.LIKE &&
    !isOlderThanOneDay &&
    (listItem.provider === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ||
      listItem.provider === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER ||
      listItem.provider === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN ||
      ([COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_PUBLIC_CONTENT].includes(listItem.provider) &&
        listItem.type === CREATE_POST_TYPE.MESSAGE)) // only Instagram direct message
  ) {
    return true;
  } else return false;
};

export const canLikeOrDislike = (socialMediaType: string) => {
  return socialMediaType === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK || socialMediaType === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN;
};

/** add alphabet and number before id's */
export const addPadStart = (id: number | string, char: string) => {
  return `${char}${id.toString().padStart(6, '0')}`;
};

export const convertFBIdsToAnchorTag = (facebookTagList: MentionData[], validTextWithFBIds: string) => {
  return facebookTagList.length
    ? facebookTagList.reduce((acc, curr) => {
        if (acc.includes(`@[${curr.id}]`)) {
          acc = acc.replace(`@[${curr.id}]`, `<a id='fb_link' href='${curr.link || ''}' target='_blank' rel='noreferrer noopener'><span id='fb_link'>${curr.name}</span></a>`);
        }
        return acc;
      }, validTextWithFBIds)
    : validTextWithFBIds;
};

/** FB page name preview adding span for page name */
// export const getValidFBTagPreview = (editorState: EditorState) => {
//   const contentState = editorState?.getCurrentContent();
//   const raw = convertToRaw(contentState);

//   const rawBlocks = raw.blocks.map((block) => {
//     let blockText = block.text;
//     // EntityRange is defines the links, mentions in the text that containes offset and length and key properties
//     if (block.entityRanges?.length && Object.keys(raw.entityMap)?.length) {
//       block.entityRanges.forEach((entityRange) => {
//         const entityRangeKeys = block.entityRanges.map((entity) => +entity.key);
//         const selectedEntityMap = raw.entityMap[entityRange?.key];
//         let mentionWithEmojiOffset = 0;
//         if (selectedEntityMap.type === 'mention') {
//           const filteredEmojisBeforeMentions = entityRangeKeys.filter((obj) => obj < entityRange?.key && obj >= block.entityRanges[0].key && raw.entityMap[obj].type === 'EMOJI');
//           filteredEmojisBeforeMentions.forEach((mention: number) => {
//             const selectedEntityRange = block.entityRanges.find((range) => range.key === mention);
//             mentionWithEmojiOffset += raw.entityMap[mention]?.data?.length - (selectedEntityRange?.length || 0);
//           });
//         }
//         const startEntity = entityRange.offset + mentionWithEmojiOffset || 0;
//         const splitText = block.text.substring(startEntity, startEntity + entityRange.length);
//         // Filter the mentions by block
//         const filteredMentionEntityKeys = entityRangeKeys.filter((key) => raw.entityMap[key].type === 'mention');
//         const selectedMention = selectedEntityMap?.type.toLowerCase() === 'mention' ? selectedEntityMap : null;

//         if (splitText?.trim()?.length && selectedMention) {
//           let getStartOffset = startEntity;
//           // Calculate the offset if multiple links added in the same block because of postition change
//           if (filteredMentionEntityKeys?.length > 1) {
//             filteredMentionEntityKeys.forEach((key) => {
//               if (key < entityRange?.key) {
//                 getStartOffset += `@[${raw.entityMap?.[key]?.data?.mention?.id}]`.length - raw.entityMap?.[key]?.data?.mention?.name?.length;
//               }
//             });
//           }
//           // Splice the text before offset to replace the content
//           blockText = blockText.substring(0, getStartOffset) + blockText.substring(getStartOffset).replace(splitText, `@[${selectedMention?.data?.mention?.id}]`);
//         }
//       });
//     }
//     return blockText;
//   });
//   // Convert string array into string
//   return rawBlocks.join(NEXT_LINE_SPLITTER);
//   //   if (acc.includes(curr.name)) acc = acc.replace(curr.name, `@[${curr.id}]`);
//   //   return acc;
//   // }, customText);
// };

export const getValidFBTagPreview = (facebookTagList: MentionData[], customText: string) => {
  return facebookTagList.reduce((acc, curr) => {
    if (acc.includes(curr.name)) acc = acc.replace(`@${curr.name}`, `@[${curr.id}]`);
    return acc;
  }, customText);
};

export const formNewInternalTag = (internalTagsList: Tag[], searchValue: string | null, isContentSupplier?: boolean) => {
  let formedTagsList: Tag[] = [];
  if (searchValue?.trim() && internalTagsList.length === 0 && !isContentSupplier) {
    formedTagsList.push({ id: 'null', name: `Add ${searchValue}` });
  } else {
    formedTagsList = internalTagsList;
  }
  return formedTagsList;
};

export const formNewAITag = (internalTagsList: any[], searchValue: string | null) => {
  let formedTagsList: any[] = [];
  if (searchValue?.trim() && internalTagsList.length === 0) {
    formedTagsList.push({ id: 'null', name: `Add ${searchValue}` });
  } else {
    formedTagsList = internalTagsList;
  }
  return formedTagsList;
};

/** Move nth decimal place */
export const getNthDecimalPlace = (value: number, decimalPlace: number) => {
  return Math.round(value) / decimalPlace;
};

/** Get unique number values from array of numbers */
export const getUniqueNumberValues = (data: number[]) => {
  return data.filter((value, index, selfArr) => selfArr.indexOf(value) === index);
};

/** Get Round a Number to 2 Decimal */
export const getRoundNumberTo2Decimal = (data: number) => {
  return data % 1 !== 0 ? Math.round((+data + Number.EPSILON) * 100) / 100 : +data;
};

/** Link navigate to next tab */
export const handleLinkNavigation = (linkUrl: null | string) => {
  if (linkUrl) window.open(linkUrl, '_blank');
};

export const impersonateUser = (
  response: loginReducerType.ILoginResponseUser,
  activeUser: loginReducerType.ILoginResponseUser | null,
  impersonatedUser: loginReducerType.ILoginResponseUser
): { response: loginReducerType.ILoginResponseUser; activeUser: loginReducerType.ILoginResponseUser } => {
  if (response.id === activeUser?.id) {
    response.impersonate_user = impersonatedUser;
    return {
      response,
      activeUser: {
        ...impersonatedUser,
        impersonated_user_id: activeUser?.id,
        impersonated_user_name: `${activeUser.first_name} ${activeUser.last_name}`
      }
    };
  } else if (response.impersonate_user) {
    const result = impersonateUser(response.impersonate_user, activeUser, impersonatedUser);
    response.impersonate_user = result.response;
    response.token = impersonatedUser.token;
    response.content_writers = impersonatedUser.content_writers || [];
    response.franchisors_managed = impersonatedUser.franchisors_managed || [];
    response.accounts_managed = impersonatedUser.accounts_managed || [];
    return { response, activeUser: result.activeUser };
  }
  return impersonateUser(response, activeUser, impersonatedUser);
};

export const deImpersonateUser = (
  response: loginReducerType.ILoginResponseUser,
  activeUserId: number
): { response: loginReducerType.ILoginResponseUser; activeUser: loginReducerType.ILoginResponseUser } => {
  if (response.impersonate_user?.id === activeUserId) {
    delete response.impersonate_user;
    return {
      response,
      activeUser: {
        id: response.id,
        email: response.email,
        first_name: response.first_name,
        last_name: response.last_name,
        company_name: response.company_name,
        admin: response.admin,
        franchisor: response.franchisor,
        content_writer: response.content_writer,
        franchisor_group_user: response.franchisor_group_user,
        detail_id: response.detail_id,
        disable_scheduled_posting: response.disable_scheduled_posting,
        has_facebook_boost_access: response.has_facebook_boost_access,
        review_alert_ratings: response.review_alert_ratings,
        receive_notification_emails: response.receive_notification_emails,
        receive_monthly_enduser_report: response.receive_monthly_enduser_report,
        mobile_phone: response.mobile_phone || null,
        profile_photo_cloudinary_id: response.profile_photo_cloudinary_id || null,
        profile_photo_url: response.profile_photo_url || null,
        profile_display_photo_url: response.profile_display_photo_url || '',
        profile_image_url: response.profile_image_url || null,
        cover_photo_url: response.cover_photo_url || null,
        avatar_url: response.avatar_url || null,
        features: response.features || [],
        franchisors: response.franchisors || [],
        accounts: response.accounts || [],
        roles: response.roles || null,
        brands: response.brands || [],
        rallio_local_assets_notification: response.rallio_local_assets_notification || false,
        rallio_local_assets_notification_enrolled_at: response.rallio_local_assets_notification_enrolled_at || '',
        direct_message_notification: response.direct_message_notification || false,
        rlc_account: response.rlc_account || false,
        revv_reference: response.revv_reference || null,
        token: response.token || '',
        content_writers: response.content_writers || [],
        franchisors_managed: response.franchisors_managed || [],
        accounts_managed: response.accounts_managed || []
      }
    };
  }
  if (response.impersonate_user) {
    const result = deImpersonateUser(response.impersonate_user, activeUserId);
    return { response: result.activeUser, activeUser: result.activeUser };
  }
  return deImpersonateUser(response, activeUserId);
};

export const updateImpersonatedUser = (response: loginReducerType.ILoginResponseUser, updatedUserData: loginReducerType.ILoginResponseUser): loginReducerType.ILoginResponseUser => {
  if (!response?.impersonate_user && response.id === updatedUserData.id) {
    return updatedUserData;
  } else if (response?.impersonate_user) {
    return { ...response, impersonate_user: updateImpersonatedUser(response?.impersonate_user, updatedUserData) };
  }
  return updateImpersonatedUser(response, updatedUserData);
};

export const loginAsImpersonatedUser = (
  response: loginReducerType.ILoginResponseUser,
  activeUser: loginReducerType.ILoginResponseUser
): { response: loginReducerType.ILoginResponseUser; activeUser: loginReducerType.ILoginResponseUser } => {
  if (!response?.impersonate_user) {
    response.impersonate_user = activeUser;
    return {
      response,
      activeUser: { ...activeUser, impersonated_user_id: response?.id, impersonated_user_name: `${response.first_name} ${response.last_name}` }
    };
  } else {
    const result = loginAsImpersonatedUser(response.impersonate_user, activeUser);
    return { response, activeUser: result.activeUser };
  }
};

export const createEmptyEditorState = () => {
  return EditorState.createEmpty();
};

export const convertStringToEditorState = (text: string) => {
  return EditorState.createWithContent(ContentState.createFromText(text));
};

export const convertEditorStateToString = (editorState: EditorState) => {
  const contentState = editorState?.getCurrentContent();
  const raw = convertToRaw(contentState);
  return raw.blocks.map((e) => e.text).join(NEXT_LINE_SPLITTER) || '';
};

// Check if the value is exponential
export const isValueHasExponentialTerm = (value: number) => {
  return value.toString().search(/[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)/g) === -1 ? false : true;
};

/** Sort object by keys - ascend/descend */
export const sortObjectByKeys = (data: commonProps.ICommonObj<any>, reverse?: boolean) => {
  const totalKeys = reverse ? Object.keys(data).sort().reverse() : Object.keys(data).sort();
  return totalKeys.reduce((acc: commonProps.ICommonObj<any>, key) => {
    acc[key] = data[key];
    return acc;
  }, {});
};

// Platform wise text length restriction
const getLengthOfSelectedText = (editorState: EditorState) => {
  const currentSelection = editorState.getSelection();
  const isCollapsed = currentSelection.isCollapsed();

  let length = 0;

  if (!isCollapsed) {
    const currentContent = editorState.getCurrentContent();
    const startKey = currentSelection.getStartKey();
    const endKey = currentSelection.getEndKey();
    const startBlock = currentContent.getBlockForKey(startKey);
    const isStartAndEndBlockAreTheSame = startKey === endKey;
    const startBlockTextLength = startBlock.getLength();
    const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
    const endSelectedTextLength = currentSelection.getEndOffset();
    const keyAfterEnd = currentContent.getKeyAfter(endKey);
    if (isStartAndEndBlockAreTheSame) {
      length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
    } else {
      let currentKey = startKey;

      while (currentKey && currentKey !== keyAfterEnd) {
        if (currentKey === startKey) {
          length += startSelectedTextLength + 1;
        } else if (currentKey === endKey) {
          length += endSelectedTextLength;
        } else {
          length += currentContent.getBlockForKey(currentKey).getLength() + 1;
        }

        currentKey = currentContent.getKeyAfter(currentKey);
      }
    }
  }
  return length;
};

export const handleBeforeInput = (editorState: EditorState, currentSocialMediaTab: string) => {
  const currentContent = editorState.getCurrentContent();
  const currentContentLength = currentContent.getPlainText('').length || 0;
  const maxLengthBasedOnSocialMedia =
    currentSocialMediaTab === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
      ? ADD_POST_CONTENT_MAX_TEXT_LENGTH.TWITTER
      : currentSocialMediaTab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
      ? ADD_POST_CONTENT_MAX_TEXT_LENGTH.INSTAGRAM
      : currentSocialMediaTab === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
      ? ADD_POST_CONTENT_MAX_TEXT_LENGTH.LINKEDIN
      : currentSocialMediaTab === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
      ? ADD_POST_CONTENT_MAX_TEXT_LENGTH.GOOGLE
      : currentContentLength;
  const selectedTextLength = getLengthOfSelectedText(editorState);
  if (
    currentSocialMediaTab &&
    [COMMON_SOCIAL_PROVIDER_TYPE.TWITTER, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE].includes(currentSocialMediaTab) &&
    currentContentLength - selectedTextLength > maxLengthBasedOnSocialMedia - 1
  ) {
    return 'handled'; // You can type only max number of characters
  } else {
    return 'not-handled';
  }
};

export const handlePastedText = (pastedText: string, editorState: EditorState, currentSocialMediaTab: string) => {
  const currentContent = editorState.getCurrentContent();
  const currentContentLength = currentContent.getPlainText('').length || 0;
  const selectedTextLength = getLengthOfSelectedText(editorState);
  const maxLengthBasedOnSocialMedia =
    currentSocialMediaTab === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
      ? ADD_POST_CONTENT_MAX_TEXT_LENGTH.TWITTER
      : currentSocialMediaTab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
      ? ADD_POST_CONTENT_MAX_TEXT_LENGTH.INSTAGRAM
      : currentSocialMediaTab === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
      ? ADD_POST_CONTENT_MAX_TEXT_LENGTH.LINKEDIN
      : currentSocialMediaTab === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
      ? ADD_POST_CONTENT_MAX_TEXT_LENGTH.GOOGLE
      : currentContentLength;
  if (
    currentSocialMediaTab &&
    [COMMON_SOCIAL_PROVIDER_TYPE.TWITTER, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE].includes(currentSocialMediaTab) &&
    currentContentLength + pastedText.length - selectedTextLength > maxLengthBasedOnSocialMedia
  ) {
    return 'handled'; // You can type only max number of characters
  } else {
    return 'not-handled';
  }
};

export const handleInsertTextInEditor = (editorState: EditorState, insertText: string) => {
  const currentContent = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const textWithInsert = Modifier.insertText(currentContent, selection, insertText);
  return EditorState.push(editorState, textWithInsert, 'insert-characters');
};

export const handleMentionInEditor = (editorState: EditorState, mention: MentionData) => {
  // let contentState = ContentState.createFromText(`${editorState.getCurrentContent().getPlainText()} `);
  let contentState = editorState?.getCurrentContent();
  let newEditorState = editorState;

  const lastOffset = contentState.getLastBlock().getLength();
  // Select offset position to replace the mention id into mention name
  const selection = SelectionState.createEmpty(contentState.getLastBlock().getKey()).merge({
    anchorOffset: lastOffset, // start of the offset
    focusOffset: lastOffset // end of the offset
  });
  // Added links into editor state
  const entityKey = contentState.createEntity('mention', 'SEGMENTED', { mention }).getLastCreatedEntityKey();
  contentState = Modifier.replaceText(contentState, selection, mention.name, undefined, entityKey);
  // Update the editor state with links
  newEditorState = EditorState.push(newEditorState, contentState, 'insert-characters');
  newEditorState = EditorState.moveFocusToEnd(newEditorState);
  newEditorState = EditorState.forceSelection(newEditorState, contentState.getSelectionAfter());
  return newEditorState;
};

export const touchFieldObjectCreator = (Obj = DL_STORE_DETAILS_CHECKFILEDS, value: boolean) => {
  const valueChanger = (currentValue: string) =>
    Object.keys(Obj[currentValue]).reduce((acc: any, curr) => {
      acc[curr] = value;
      return acc;
    }, {});
  const keys = Object.keys(Obj);
  const b = keys.reduce((acc: any, curr) => {
    acc[curr] = valueChanger(curr);
    return acc;
  }, {});
  return b;
};

export const getUniqueValues = <T>(value: T[]) => {
  return [...new Set(value)];
};

export const getCloudinaryIdFromMediaUrl = (mediaUrl: string) => {
  return mediaUrl.split('/')[5]?.split('.')[0];
};

export const scrollToTop = (elementId?: string) => {
  const scrollElement = document.getElementById(elementId || 'scrollTarget');
  if (scrollElement) {
    scrollElement.scroll({ top: 0, left: 0 });
  }
};

export const scrollToBottom = (id: string) => {
  const scrollElement = document.getElementById(id);
  if (scrollElement) {
    scrollElement.scroll({ top: scrollElement.scrollHeight, left: 0 });
  }
};

export const scrollTolLeftToRight = (elementId: string) => {
  const scrollElement = document.getElementById(elementId);
  if (scrollElement) {
    scrollElement.scrollLeft += 5000;
  }
};

export const scrollToView = (element: string) => {
  const scrollElement = document.getElementById(element);
  if (scrollElement) scrollElement.scrollIntoView();
};

export const getModifiedObjects = (obj1: settingsAIPlayBookReducerTypes.IAIPlayBook, obj2: settingsAIPlayBookReducerTypes.IAIPlayBook): Partial<settingsAIPlayBookReducerTypes.IAIPlayBook> => {
  const obj: { [key: string]: string | number | boolean | settingsAIPlayBookReducerTypes.ISettingsAIPlayBookQuestions | null | undefined } = {};
  Object.keys(obj1).forEach((key) => {
    const objKey = key as keyof settingsAIPlayBookReducerTypes.IAIPlayBook;
    if (obj1[objKey] !== obj2[objKey]) {
      return (obj[objKey] = obj2[objKey]);
    }
  });
  return obj;
};

export const updateAccountSwitcherName = ({
  updatedName,
  id,
  userOwnership,
  currentAccountOrFranchisor,
  topLevelFranchisorObj,
  franchisorGroups,
  franchisors,
  accounts,
  dispatch
}: commonAccountSwitcher.IUpdateAccountOrFranchisorName) => {
  if (currentAccountOrFranchisor && currentAccountOrFranchisor.name !== updatedName) {
    dispatch(
      accountSwitcherResponse({
        current_name: updatedName,
        id,
        userOwnership,
        current_account_or_franchisor: { ...currentAccountOrFranchisor, name: updatedName },
        franchisors: userOwnership === USER_OWNERSHIP.FRANCHISOR ? franchisors.map((it) => (it.id === id ? { ...it, name: updatedName } : it)) : franchisors,
        accounts: userOwnership === USER_OWNERSHIP.ACCOUNT ? accounts.map((it) => (it.id === id ? { ...it, name: updatedName } : it)) : accounts,
        top_level_franchisor: topLevelFranchisorObj ? { ...topLevelFranchisorObj, name: updatedName } : null,
        franchisor_groups: franchisorGroups
      })
    );
  }
};

export const formatReviewPhoneNumber = (reviewText: string) => {
  let text;
  const reviewTextNumberArr = reviewText.match(GET_NUMBERS_ONLY_REGEX);
  if (reviewTextNumberArr && reviewTextNumberArr.length) {
    for (const reviewTextNumber of reviewTextNumberArr) {
      const contactNumb = reviewTextNumber.substr(-10, 10);
      const phoneNumber = parsePhoneNumber(contactNumb, 'US');
      if (phoneNumber && reviewTextNumber.length === 10) {
        text = reviewText.replace(reviewTextNumber, phoneNumber?.formatNational());
      }
    }
  } else {
    text = reviewText;
  }
  return text;
};

export const convertURLAsLink = (customText: string, customName?: string, className?: string) => {
  // eslint-disable-next-line
  const updatedText = customText
    ? customText.replace(URL_REGEX_WITH_OPTIONAL_PROTOCAL, (url: string) => {
        const trimmedURL = url.trim();
        const urlIsHttp = trimmedURL.startsWith('http://');
        const newProtocol = urlIsHttp ? 'http' : 'https';
        const linkRef = `${newProtocol}://${trimmedURL.replace('https://', '').replace('http://', '')}`;
        return `<a id='fb_link' href='${linkRef}' target='_blank' rel='noreferrer noopener' className='${className || ''}'><span id='fb_link'>${customName || url}</span></a>`;
      })
    : '';
  if (customName && socialMediaCallToActionCallLabels.includes(customName)) {
    return `<span>${customName}</span>`;
  }
  return updatedText;
};

export const removeHttpWebFromUrl = (url: string) => {
  const hostName = url;
  return hostName.includes('www.') ? hostName.replace('www.', '') : hostName;
};

export const removeHashtagsFromText = (hashtags: string[], text: string) => {
  let modifiedText = text;
  hashtags.forEach((hashtag) => {
    const hashtagRegex = new RegExp(`(${hashtag})( )|$`, 'g');
    modifiedText = modifiedText.replace(hashtagRegex, '') || '';
  });
  return modifiedText;
};

export const getUniqueHashtags = (selectedHashtags: string[], text: string) => {
  return selectedHashtags.filter((hashtag) => {
    const hashtagRegex = new RegExp(`(${hashtag})\\b`, 'g');
    return !text.match(hashtagRegex);
  });
};

export const insertHashtagAtCursorPosition = (editorState: EditorState, uniqueHashtags: string[]) => {
  const editorText = convertEditorStateToString(editorState);

  const currentContentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const currentCursorPosition = selectionState.getFocusOffset();

  const currentCursorPositionText = editorText[currentCursorPosition - 1];
  const hastagsText = `${uniqueHashtags.length ? `${currentCursorPosition === 0 || currentCursorPositionText === ' ' ? '' : ' '}${uniqueHashtags.join(' ')} ` : ''}`;

  const mergedContentState = Modifier.replaceText(currentContentState, selectionState, hastagsText);
  let newEditorState = EditorState.set(editorState, { currentContent: mergedContentState });
  newEditorState = EditorState.forceSelection(newEditorState, mergedContentState.getSelectionAfter());
  return newEditorState;
};

export const handleHashtagsInCreator = (
  selectedHashtagsList: string[],
  hashTagsLimit: string[],
  dispatch: Dispatch<any>,
  aiHashtagsList: string[],
  captionState: aiContentCreatorReducerTypes.IAICaptions
) => {
  if (selectedHashtagsList.length) {
    const selectedHashtags = selectedHashtagsList.filter((selectedHashtag) => hashTagsLimit.includes(selectedHashtag));
    dispatch(addPostSelectHashtagResponse(selectedHashtags));
  }
  if (aiHashtagsList.length) {
    const aiSelectedHashtags = aiHashtagsList.filter((selectedHashtag) => hashTagsLimit.includes(selectedHashtag));
    dispatch(
      setCreatorAICaptions({
        ...captionState,
        selectedHashtags: aiSelectedHashtags
      })
    );
  }
};

export const isValidUrl = (urlString: string) => {
  const urlPattern = new RegExp(URL_REGEX_WITH_OPTIONAL_PROTOCAL);
  return !!urlPattern.test(urlString);
};

export const convertValidUrlFromLinkText = (linkText: string) => {
  const isLinkHasWww = linkText.split('.')[0].includes('www');
  const linkWithoutHttp = linkText.replace(/^(https?:\/\/)?(www\.)?/i, '');
  if (linkText.includes('https://')) {
    return linkText;
  }
  return `https://${isLinkHasWww ? `www.` : ''}${linkWithoutHttp}`;
};

export const generateCustomerPersonaFieldData = (customerPersonaText: string) => {
  const data: Record<string, string> = { name: '', occupation: '' };
  if (customerPersonaText) {
    PERSONA_FIELDS_FOR_CAPTION_GENERATION.forEach((field) => {
      const CustomerPersonaRegex = new RegExp(`${field.label}:\\s*([^\n]+)`);
      const nameArr = customerPersonaText?.match(CustomerPersonaRegex) || null;
      data[field.key as string] = nameArr?.[1] || '';
    });
  }
  return data;
};
