// import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { setKeyWordData } from 'analytics/actions';
import { ImageValidation } from 'widgets/Media';
import { ReputationReviews } from 'containers/Reputation/Reviews';
import { KeywordsModalFilter } from 'analytics/layouts/Filter/SubPages/KeyWordsModalFilter';
import { useParamsDeconstructor } from 'utils/hooks';

const initialFilterValue = {
  ratings: []
};

export const KeywordsModal = ({ dispatch, uniqueKeyWord }: { dispatch: any; uniqueKeyWord: string | null; navigate: any }) => {
  const navigate = useNavigate();
  const { queryParams } = useParamsDeconstructor(initialFilterValue);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  // const [prevQuery, setPrevQuery] = useState()

  // useEffect(() => {
  //   return () => {
  //     navigate({ search: `?${new URLSearchParams({ selected_tile: 'keywords' }).toString()}` });
  //   };
  // }, [navigate]);

  const handleCancel = () => {
    dispatch(setKeyWordData(null));
    if (queryParams.ratings) {
      const queryParamsClone = { ...queryParams };
      delete queryParamsClone.ratings;
      navigate({ search: `?${new URLSearchParams(queryParamsClone).toString()}` });
    }
  };

  return (
    <Modal show={Boolean(uniqueKeyWord)} centered size="lg" className="connection-secondary add-location ralprofile-hl add-booster keywords-modal-body">
      <Modal.Body>
        <div className="sec-conn__wrp">
          <div className="sec-header modal-mbl-filter--main">
            <div className="header-item__wrp">
              <span className="title">{uniqueKeyWord?.replace(/^./, uniqueKeyWord[0].toUpperCase())}</span>
              <ImageValidation defaultImg="close" customName="close" customClassname="close" onClick={handleCancel} />
              <button className={`modal-mbl--filter mobile-filter ${isFilterOpen ? 'open' : ''}`} onClick={() => setIsFilterOpen(!isFilterOpen)} />
            </div>
          </div>
          <ReputationReviews isStats={false} isCoachMark={false} />
          <div className={`keyword-mbl-filter ${isFilterOpen ? 'open' : ''}`}>
            <KeywordsModalFilter />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
