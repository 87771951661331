import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { ImageGrid } from 'react-fb-image-video-grid';

import { CustomSwiper, ReactVideoPlayer, ImageValidation, Image } from 'widgets/Media';
import { apAccountContainerTypes, IStore } from 'types';
import {
  COMMON_SOCIAL_PROVIDER_TYPE,
  AP_FRAN_SHOW_LINE_BREAK_TYPES,
  MG_MEDIA_TYPE,
  CREATOR_FB_PREVIEW_FOOTER_ICONS,
  CREATOR_INSTA_LEFT_PREVIEW_FOOTER_ICONS,
  CREATOR_INSTA_RIGHT_PREVIEW_FOOTER_ICONS,
  CREATOR_TWITTER_PREVIEW_FOOTER_ICONS,
  CREATOR_LINKEDIN_PREVIEW_FOOTER_ICONS,
  SOCIAL_MEDIA_PREVIEW_TITTLE_AND_LOGO,
  GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS
} from 'utils/constants';
import { Paragraph } from 'widgets/Text';
import { FacebookReelPreview, FacebookStoryPreview, InstagramStoryPreview, SocialMediaLinkPreview, TiktokPreview } from 'widgets/Preview';
import { convertURLAsLink, getPermittedImgsBasedOnPlatform, getValidFBTagPreview } from 'utils/helpers';
import { PLATFORM_FILTER } from 'analytics/utils';
import { SocialMediaPreviewFooterIcons } from '../CommonModal';

export const RightCreatorPreviewSection = (props: apAccountContainerTypes.IAddPostSocialPlatformProps) => {
  const { accessibleSocialMedia } = props;
  // const { id, userOwnership } = useAccountSwitcherData();

  const instagramPhotoPreviewUrl = useSelector((state: IStore) => state.addPostAccount.instaPhotoPreviewObj.photoUrl);
  const facebookPhotoPreviewUrl = useSelector((state: IStore) => state.addPostAccount.fbPhotoPreviewObj.photoUrl);
  const brandImageUrl = useSelector((state: IStore) => state.accountSwitcher.brandImageUrl ?? '');
  // const menuAccess = useSelector((state: IStore) => state.accountSwitcher.menu_access);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const selectedFBTagList = useSelector((state: IStore) => state.addPostFranchisor.selectedFBTagList);
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const topLevelFranchisor = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisor);
  const { customText, personalizedText, postStatus, isThumbnailShow } = contentObj;
  // const reducerTextEditorState = useSelector((state: IStore) => state.addPostFranchisorAction.textEditorState);
  // const personalizedEditorState = useSelector((state: IStore) => state.addPostFranchisorAction.personalizedEditorState);

  const [playVideo, setPlayVideo] = useState<string[]>([]);

  useEffect(() => {
    if (!accessibleSocialMedia.length) setPlayVideo([]);
  }, [accessibleSocialMedia.length]); // eslint-disable-line

  const ImageGridCol = useCallback(
    ({ imageUrls }: { imageUrls: string[] }) => {
      return (
        <ImageGrid>
          {imageUrls.map((image, index) => (
            <img alt="ig" src={image} key={`selected-image-${index}`} />
          ))}
        </ImageGrid>
      );
    },
    [selectedMediaList] // eslint-disable-line
  );

  /* const siteRestrictions = useMemo(() => {
    const restrictions = {
      hideLogo: false
    };
    if (menuAccess) {
      const siteRestrictions = menuAccess?.site_restrictions;
      if (id && userOwnership) {
        if (userOwnership === USER_OWNERSHIP.FRANCHISOR) {
          restrictions.hideLogo = Boolean(siteRestrictions?.hub_user?.includes('logo'));
        } else if (userOwnership === USER_OWNERSHIP.ACCOUNT) {
          restrictions.hideLogo = Boolean(siteRestrictions?.location_user?.includes('logo'));
        }
      }
    }
    return restrictions;
  }, [menuAccess, id, userOwnership]); */

  return (
    <div className="addpost-preview-section-wrp fbInMain">
      <div className="post-preview">
        {!accessibleSocialMedia.length ? (
          <div className="p-loader">
            <div className="p-mock-header heading">
              <div>
                <div className="p-mock-avatar" />
              </div>
              <div className="p-mock-title">
                <div className="p-mock-line" />
              </div>
            </div>
            <div className="card-bg">
              <div className="p-mock-header">
                <div>
                  <div className="p-mock-avatar" />
                </div>
                <div className="p-mock-title">
                  <div className="p-mock-line" />
                  <div className="p-mock-line" />
                </div>
              </div>

              <div className="p-mock-content">
                <div className="p-mock-line" />
              </div>
            </div>
          </div>
        ) : (
          accessibleSocialMedia
            .sort((a, b) => PLATFORM_FILTER.findIndex((it) => it.value === a) - PLATFORM_FILTER.findIndex((it) => it.value === b))
            .map((socialMediaName, index) => {
              const imageUrls =
                socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
                  ? contentObj?.facebook_story
                    ? facebookPhotoPreviewUrl.storyUrls || []
                    : selectedMediaList.map((datum) => datum.medium_size_url || '')
                  : socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
                  ? contentObj?.instagram_story
                    ? instagramPhotoPreviewUrl.storyUrls || []
                    : instagramPhotoPreviewUrl.previewUrls || []
                  : selectedMediaList.map((datum) => datum.medium_size_url || '');
              const imagesLimitBasedOnPlatform = getPermittedImgsBasedOnPlatform(socialMediaName, imageUrls);
              const facebookTags = postStatus.facebook ? selectedFBTagList : [];
              return (customText && customText[socialMediaName]) || selectedMediaList.length || linkData?.id ? (
                <div className={`p-content ${socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE ? 'smType-google' : ''}`} key={`brand-creator-preview-${index}`}>
                  <div className="p-content-header">
                    <div className={socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? 'p-avatar-box' : 'p-avatar-box-twitter'}>
                      {/* <div> */}
                      <Image isImageExists={false} customImgName={SOCIAL_MEDIA_PREVIEW_TITTLE_AND_LOGO.find((previewObj) => previewObj.medium === socialMediaName)?.logo || ''} customName={'More'} />
                      {/* </div> */}
                    </div>
                    <h4>{SOCIAL_MEDIA_PREVIEW_TITTLE_AND_LOGO.find((previewObj) => previewObj.medium === socialMediaName)?.label || ''}</h4>{' '}
                  </div>
                  <div
                    className={`card-bg ${socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM ? 'ig-assets' : ''}
                  ${selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO && socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK ? 'cb-tiktok' : ''} 
                  ${
                    (socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && contentObj?.facebook_story) ||
                    (socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && contentObj?.facebook_reel) ||
                    (socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && contentObj?.instagram_story)
                      ? 'fb-in-active'
                      : ''
                  } `}
                  >
                    {selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO && socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK && (
                      <TiktokPreview
                        userName={topLevelFranchisor}
                        videoUrl={selectedMediaList[0]?.medium_size_url || ''}
                        thumbnailUrl={selectedMediaList[0]?.thumbnail_url || ''}
                        description={personalizedText?.tiktok || customText.tiktok || ''}
                      />
                    )}
                    {socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? (
                      contentObj?.facebook_story ? (
                        <FacebookStoryPreview userName={topLevelFranchisor} media={selectedMediaList?.[0]} imgStoryUrl={imageUrls[0]} profilePic={brandImageUrl} />
                      ) : contentObj?.facebook_reel ? (
                        <FacebookReelPreview
                          userName={topLevelFranchisor}
                          description={customText.facebook || ''}
                          videoUrl={selectedMediaList[0]?.medium_size_url || ''}
                          thumbnailUrl={selectedMediaList[0]?.thumbnail_url || ''}
                          profilePic={brandImageUrl}
                        />
                      ) : null
                    ) : socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && contentObj?.instagram_story ? (
                      <InstagramStoryPreview userName={topLevelFranchisor} media={selectedMediaList?.[0]} imgStoryUrl={imageUrls[0]} profilePic={brandImageUrl} />
                    ) : null}
                    {socialMediaName !== COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK &&
                    !(socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && (contentObj?.facebook_story || contentObj?.facebook_reel)) &&
                    !(socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && contentObj?.instagram_story) ? (
                      <div className="p-header" key={`creator-profile-${index}`}>
                        <div className="p-header-title">
                          <div className="p-avatar">
                            <ImageValidation imgUrl={brandImageUrl} defaultImg={'user-avatar-common.png'} isNotSvgFormat customName={'Franchisor Profile Item'} />
                          </div>
                          <div className="p-title">
                            <h5>{topLevelFranchisor}</h5>
                            {socialMediaName !== COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && <Paragraph customText={'Just now'} />}
                          </div>
                        </div>
                        {socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && (
                          <div className="p-header-menu">
                            <div className="insta-dots-rotate">
                              <ImageValidation isImgValid defaultImg={'dot-menu'} customName={'p-avatar'} />
                            </div>
                          </div>
                        )}
                        {socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && (
                          <div className="p-header-menu">
                            <div className="insta-dots-rotate">
                              <ImageValidation isImgValid defaultImg="share-dots" customName="shareDots" />
                              <ImageValidation isImgValid defaultImg="more-dots-v" customName="moreDots" />
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                    {socialMediaName !== COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK &&
                    !(socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && (contentObj?.facebook_story || contentObj?.facebook_reel)) &&
                    !(socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && contentObj?.instagram_story) ? (
                      <div className="p-body previewtLinks p-0">
                        {socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && (
                          <div className="am-media-preview pmg">
                            <div>
                              <ImageValidation imgUrl={imagesLimitBasedOnPlatform[0] || linkData?.link_preview_image_url} customName={'Image asset'} />
                            </div>
                          </div>
                        )}
                        <div className="msg-wrapper">
                          {personalizedText[socialMediaName] && ![COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK].includes(socialMediaName) && !contentObj.facebook_story ? (
                            <Paragraph
                              facebookTagList={socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? facebookTags : []}
                              customClassname="gm-text"
                              customText={getValidFBTagPreview(selectedFBTagList, personalizedText[socialMediaName])}
                              actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW}
                            />
                          ) : customText && socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && !contentObj.facebook_story ? (
                            <Paragraph facebookTagList={facebookTags} customText={getValidFBTagPreview(selectedFBTagList, customText.facebook)} actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW} />
                          ) : socialMediaName !== COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && socialMediaName !== COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK ? (
                            <Paragraph customClassname="gm-text" customText={customText[socialMediaName] || ''} actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW} />
                          ) : null}
                        </div>
                        <div className="am-media-preview pmg">
                          {selectedMediaList.length >= 1 && selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.PHOTO && socialMediaName !== COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE ? (
                            imagesLimitBasedOnPlatform.length > 1 ? (
                              [COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER].includes(socialMediaName) ? (
                                <ImageGridCol imageUrls={imagesLimitBasedOnPlatform} />
                              ) : (
                                <CustomSwiper imagesList={imagesLimitBasedOnPlatform} isVirtualSlider />
                              )
                            ) : (
                              <div>
                                <ImageValidation imgUrl={imagesLimitBasedOnPlatform[0]} customName={'Image asset'} />
                              </div>
                            )
                          ) : null}
                          {selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO && socialMediaName !== COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && (
                            <div className="video-outer-element">
                              <ReactVideoPlayer
                                videoUrl={selectedMediaList[0]?.medium_size_url || ''}
                                customClassname={'video'}
                                // light={`${config.cdnImgUrl}play-tiktok.svg`}
                                isPlayControl={playVideo.includes(socialMediaName)}
                                onClick={() => {
                                  if (contentObj && contentObj.postStatus[`${socialMediaName}`]) {
                                    setPlayVideo([...playVideo, socialMediaName]);
                                  }
                                }}
                                light={selectedMediaList[0]?.thumbnail_url}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                    {linkData?.id ? (
                      <SocialMediaLinkPreview
                        imageUrl={linkData?.link_preview_image_url || null}
                        linkPreviewTitle={linkData?.link_preview_title || ''}
                        linkUrl={linkData?.url || ''}
                        isShowThumbnail={isThumbnailShow || false}
                        linkPreviewDescription={linkData?.link_preview_description || ''}
                        socialMedia={socialMediaName}
                        isLinkCustomized={linkData?.link_preview_customized || false}
                        actionType={socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? contentObj?.facebook_call_to_action_type : contentObj?.google_call_to_action_type || null}
                      />
                    ) : null}

                    {socialMediaName &&
                    [COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER, COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN].includes(socialMediaName) &&
                    !(socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && (contentObj?.facebook_story || contentObj?.facebook_reel)) ? (
                      <SocialMediaPreviewFooterIcons
                        socialMedia={socialMediaName}
                        footerIconsList={
                          socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
                            ? CREATOR_FB_PREVIEW_FOOTER_ICONS
                            : socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
                            ? CREATOR_TWITTER_PREVIEW_FOOTER_ICONS
                            : CREATOR_LINKEDIN_PREVIEW_FOOTER_ICONS
                        }
                      />
                    ) : null}
                    {socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && !contentObj?.instagram_story && (
                      <>
                        <div className="p-footer" />
                        <div className="insta-social-sec-wrp">
                          <div className="lefticon">
                            <SocialMediaPreviewFooterIcons socialMedia={`${socialMediaName}-left`} footerIconsList={CREATOR_INSTA_LEFT_PREVIEW_FOOTER_ICONS} />
                          </div>

                          <div className="righticon">
                            <SocialMediaPreviewFooterIcons socialMedia={`${socialMediaName}-right`} footerIconsList={CREATOR_INSTA_RIGHT_PREVIEW_FOOTER_ICONS} />
                          </div>
                        </div>
                        <div className="msg-wrapper">
                          {socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM ? (
                            <Paragraph customText={personalizedText[socialMediaName] || customText[socialMediaName] || ''} customClassname={'insta-text-main'} />
                          ) : null}
                        </div>
                        <Paragraph customText={'Just now'} customClassname={'insta-text-sub'} />
                      </>
                    )}
                    {socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && contentObj.google_call_to_action_url && contentObj.google_call_to_action_type && (
                      <>
                        <div className="linkWrap">
                          <span>
                            {parse(
                              convertURLAsLink(
                                contentObj.google_call_to_action_url,
                                GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS.find((option) => option.value === contentObj.google_call_to_action_type)?.label || '',
                                'add'
                              )
                            )}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : null;
            })
        )}
      </div>
    </div>
  );
};
