import { useState, useEffect, useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { EditorState } from 'draft-js';

import { IStore, postsContainerTypes, postsReducerTypes } from 'types';
import {
  RIPPLE_COLOR,
  TOOLTIP_PLACEMENT,
  USER_OWNERSHIP,
  AUTO_SCHEDULE_DISABLE_PACKAGE,
  MANUALLY_SCHEDULE_DISABLE_PACKAGE,
  CREATOR_USER_OWNERSHIP,
  POSTS_EDIT_DETAIL_OBJ,
  POSTS_SYN_CONTENT_OBJ,
  AP_ACTION_TYPES,
  ROUTES,
  COMMON_PAGE_TYPE,
  POSTS_DETAIL_TAB_NAMES,
  POSTS_INIT_FILTER_DATA,
  POSTS_PACKAGE_EXPIRED_MSG,
  CreatorInstaPhotoType,
  COMMON_SOCIAL_PROVIDER_TYPE,
  ALERT_BOX_TYPES,
  AP_FRAN_MODAL_NAMES,
  FranchisorSyndicationMediaType,
  FranCreatorScheduleDateType,
  POSTS_SYNDICATE_EXPIRED_MSG
} from 'utils/constants';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { CustomTooltip } from 'widgets/Tooltip';
import { alertBoxCall } from 'components';
import {
  copyPostsRequest,
  postsUpdateSavedPostRequest,
  postsEditContentDraftRequest,
  creatorSetComponentName,
  addPostGetSelectedFBTagList,
  addPostSetTags,
  addPostSetSelectedMediaList,
  addPostSetLinkData,
  addPostGetActionTypeForPost,
  addPostGetEditSavedPostObj,
  addPostUpdateSavedPostDetails,
  rejectSavedPostFromHubRequest,
  postDetailDataReset,
  addPostSetCustomTextEditorData,
  addPostSetInstaPhotoPreviewObj,
  addPostSetCampaignData,
  addPostSaveAsScheduledPostRequest,
  addPostSaveMessageReset,
  addPostResetAll,
  addPostFranResetAll,
  addPostActionsResetAll,
  setCreatorModalName,
  postsSyndicateExpireDateUpdateRequest,
  addPostMassUpdateForScheduledPostRequest,
  setPostsSyndicateExpireDate,
  addPostEnablePostNow,
  addPostGetSelectedContentObj,
  addPostUpdateCustomTextEditor
} from 'actions';
import {
  getAccountUpdatedPostDetails,
  getUpdatedTagsList,
  getEditOrSchedulePost,
  getAddMediaListForCreator,
  getCreatorLinkData,
  getPostContentTextField,
  getPostContentValidText,
  canEdit,
  canApprove,
  atLeastApproved,
  canBeScheduled,
  canScheduleAccountPost,
  isSocialMediaActive,
  handleEditContentPostDispatch,
  getUTCDateAndTimeForSelectedData,
  checkCurrentDateGreater,
  checkCurrentDateLessThan,
  getUTCDateForPosts,
  convertStringToDate,
  convertStringToEditorState
} from 'utils/helpers';
import { useAccountSwitcherData, useActivePlatform, useAllowAccess, useParamsDeconstructor, useSiteRestriction } from 'utils/hooks';
import { PostSyndicateContentModal } from '../Modals/PostSyndicateModal';
import { PostDeleteConfirmationModal } from '../Modals/PostDeleteConfirmationModal';
import { FranSyndicatePostModal } from 'containers/Content/Creator/BrandOrHubSubPages';
import { PostPublishStatusModal } from '../Modals/PostPublishStatusModal';
import { ConnectSocialMediaModal } from '../Modals/ConnectSocialMediaModal';

export const PostsDetailActions = ({ postId, setShowDeleteModal }: postsContainerTypes.IPostsDetailActionProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, userOwnership, currentRoute, optionalParams } = useAccountSwitcherData();
  const { postsRestriction } = useSiteRestriction();

  const { franchisorFullAccess } = useAllowAccess();
  const { queryParams } = useParamsDeconstructor(POSTS_INIT_FILTER_DATA);
  const activePlatforms = useActivePlatform(userOwnership === USER_OWNERSHIP.ACCOUNT ? false : false);

  const postsDetails = useSelector((state: IStore) => state.posts.postsDetails);
  const isCopyPostFetching = useSelector((state: IStore) => state.posts.isCopyPostFetching);
  const isRejectPostFetching = useSelector((state: IStore) => state.posts.isRejectPostFetching);
  const postsMediaList = useSelector((state: IStore) => state.posts.postsMediaList);
  const postsLinks = useSelector((state: IStore) => state.posts.postsLinks);
  const postsCopy = useSelector((state: IStore) => state.posts.postsCopy);
  const tzInfoName = useSelector((state: IStore) => state.accounts?.accountDetails?.account?.tzinfo_name || '');
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor?.contentObj);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin || false);
  const isFranchisor = useSelector((state: IStore) => state.login.activeUser?.franchisor || false);
  const overriddenCustomPostsLocked = useSelector((state: IStore) => state.accounts.permissions.franchiseePermissions?.overridden_custom_posts_locked || false);
  const accountId = useSelector((state: IStore) => state.accounts.accountDetails?.account?.id);
  const isFranchisee = useSelector((state: IStore) => state.accounts.accountDetails?.account_profiles[0]?.is_franchisee || false);
  const campaigns = useSelector((state: IStore) => state.posts.postsDetails?.campaigns || []);
  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const apActionType = useSelector((state: IStore) => state.addPostAccount.apActionType);
  const savedPostId = useSelector((state: IStore) => state.addPostAccount.savedPostId);
  const addPostMessage = useSelector((state: IStore) => state.addPostAccount.addPostMessage);
  const franchisorSyndication = useSelector((state: IStore) => state.franchisors.currentFranDetails?.franchisors_for_syndication);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier);
  const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);
  const postsSyndicateExpireDate = useSelector((state: IStore) => state.posts.postsSyndicateExpireDate);

  const [editAccountDetail, setEditAccountDetail] = useState(POSTS_EDIT_DETAIL_OBJ);
  const [syndicateContent, setSyndicateContent] = useState(POSTS_SYN_CONTENT_OBJ);
  const [isRemovePostModal, setIsRemovePostModal] = useState(false);
  const [isSchedulePost, setIsSchedulePost] = useState(false);
  const [isSyndicateModalOpen, setIsSyndicateModalOpen] = useState(false);
  const [isPublishStatusModalOpen, setIsPublishStatusModalOpen] = useState(false);
  const [isConnectSocialPlatformModalOpen, setConnectSocialPlatformModalOpen] = useState(false);
  const campaignPost = Boolean(queryParams?.campaign_id || 0);

  useEffect(() => {
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR && postsDetails?.id && optionalParams[2] === POSTS_DETAIL_TAB_NAMES.SCHEDULE) {
      handleEditContentPost(false);
    }
    if (userOwnership === USER_OWNERSHIP.ACCOUNT && postsDetails?.id && optionalParams[1] === POSTS_DETAIL_TAB_NAMES.SCHEDULE) {
      handleEditAccountPost(false);
    }
  }, [postsDetails?.id, optionalParams[2], optionalParams[1]]); // eslint-disable-line

  useEffect(() => {
    if (accountId) {
      setEditAccountDetail({ ...editAccountDetail, isGetAccountDetail: true });
    }
  }, [accountId]); // eslint-disable-line

  useEffect(() => {
    dispatch(setPostsSyndicateExpireDate({ start_date: postsDetails?.start_date || null, end_date: postsDetails?.end_date || null }));
  }, [postsDetails?.start_date, postsDetails?.end_date, dispatch]);

  useEffect(() => {
    if (postsDetails?.id && editAccountDetail.isPostDetailLoading && editAccountDetail.isGetAccountDetail) {
      handleEditAccountPost(true);
    }
  }, [postsDetails?.id, editAccountDetail.isPostDetailLoading, editAccountDetail.isGetAccountDetail]); // eslint-disable-line

  useEffect(() => {
    if (syndicateContent.isSyndicateConfirm) {
      handleScheduleContent();
    }
  }, [syndicateContent.isSyndicateConfirm]); // eslint-disable-line

  useEffect(() => {
    if (postsCopy.length) handleCancelPostDetailView();
  }, [postsCopy.length]); // eslint-disable-line

  useEffect(() => {
    if (isSchedulePost) {
      routePush(`/${userOwnership}/${id.toString()}/${currentRoute}/${`/${postId.toString()}/${POSTS_DETAIL_TAB_NAMES.SCHEDULE}`}`, queryParams);
      setIsSchedulePost(false);
    }
  }, [isSchedulePost]); // eslint-disable-line

  useEffect(() => {
    if (addPostMessage) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, addPostMessage, { success: false });
      dispatch(addPostSaveMessageReset());
      handleCreatorReset();
    }
  }, [addPostMessage, dispatch]); // eslint-disable-line

  useEffect(() => {
    if (apActionType && savedPostId) {
      dispatch(
        addPostSaveAsScheduledPostRequest({
          savedPostId,
          scheduledPost: {
            scheduled_for: savedPostDetails?.schedule_date_time.utcFormat
          }
        })
      );
    }
  }, [apActionType, savedPostId]); // eslint-disable-line

  const isPostExpiry = useMemo(() => {
    if (postsDetails?.end_date) {
      return !checkCurrentDateLessThan(postsDetails?.end_date);
    }
  }, [postsDetails?.end_date]);

  const handleCreatorReset = () => {
    dispatch(addPostResetAll());
    dispatch(addPostFranResetAll());
    dispatch(addPostActionsResetAll());
  };

  const handleCancelPostDetailView = () => {
    dispatch(postDetailDataReset());
    routePush(`/${userOwnership}/${id.toString()}/${currentRoute}`, queryParams);
  };

  const handlePostApproveContent = (detailsData: postsReducerTypes.IPostDetailsData) => {
    if (detailsData?.account?.id) {
      dispatch(postsUpdateSavedPostRequest({ ...getPostContentValidText(detailsData), id: String(detailsData.id), status_id: 3, type: 'approve' }));
    } else if (detailsData && Object.keys(detailsData).length) {
      dispatch(
        postsEditContentDraftRequest({
          contentId: detailsData.id,
          reqBody: detailsData
            ? {
                ...detailsData,
                status_id: 3
              }
            : null
        })
      );
    }
  };

  const handleSaveExpireDate = () => {
    if (postsDetails?.id) {
      dispatch(
        postsSyndicateExpireDateUpdateRequest({
          contentId: postsDetails.id,
          actionType: FranchisorSyndicationMediaType.CONTENT,
          reqBody: {
            start_date: postsSyndicateExpireDate?.start_date,
            end_date: postsSyndicateExpireDate?.end_date
          }
        })
      );
      dispatch(addPostMassUpdateForScheduledPostRequest(postsDetails?.id));
    }
  };

  const handlePostsCopy = (postsCopyDetailId: number) => {
    dispatch(copyPostsRequest(userOwnership === USER_OWNERSHIP.FRANCHISOR ? { contentId: postsCopyDetailId } : { savedPostId: postsCopyDetailId }));
  };

  const handleEditOrSchedulePost = () => {
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR && postsDetails?.account?.id) {
      dispatch(creatorSetComponentName(CREATOR_USER_OWNERSHIP.LOCATION_CONTENT));
    }
    if (postsDetails) {
      if (userOwnership === USER_OWNERSHIP.ACCOUNT || (userOwnership === USER_OWNERSHIP.FRANCHISOR && postsDetails?.account?.id)) {
        handleEditAccountPost(true);
      } else {
        handleEditContentPost(true);
      }
      if (postsDetails?.start_date) {
        dispatch(addPostEnablePostNow(!checkCurrentDateGreater(postsDetails?.start_date) ? false : true));
      }
    }
  };

  const handleContentSchedulePost = () => {
    setSyndicateContent(postsDetails?.syndicate_to_account_databases ? { ...syndicateContent, isShowModal: true } : { isShowModal: false, isSyndicateConfirm: true });
    if (postsDetails?.start_date) {
      dispatch(addPostEnablePostNow(!checkCurrentDateGreater(postsDetails?.start_date) ? false : true));
    }
  };

  const handleScheduleContent = () => {
    routePush(
      `/${userOwnership}/${id.toString()}/${currentRoute}/${
        optionalParams[0] === COMMON_PAGE_TYPE.DETAILS ? `preview/${postId.toString()}/${POSTS_DETAIL_TAB_NAMES.SCHEDULE}` : `${postId.toString()}/${POSTS_DETAIL_TAB_NAMES.SCHEDULE}`
      }`,
      queryParams
    );
    setSyndicateContent(POSTS_SYN_CONTENT_OBJ);
  };

  const handleEditContentPost = (isEdit: boolean) => {
    if (postsDetails) {
      if (isEdit) setEditAccountDetail({ ...editAccountDetail, isPostDetailLoading: true });
      handleEditContentPostDispatch(activePlatforms, postsDetails, postsMediaList, postsLinks, contentObj, dispatch);
      if (campaigns?.length) {
        dispatch(addPostSetCampaignData(campaigns));
      }
      if (isEdit) handleNavigateToCreatorPage();
    }
  };

  const handleEditAccountPost = (isEdit: boolean) => {
    if (postsDetails?.id) {
      if (isEdit) setEditAccountDetail({ ...editAccountDetail, isPostDetailLoading: true });
      dispatch(addPostGetActionTypeForPost(AP_ACTION_TYPES.EDIT));
      dispatch(addPostGetEditSavedPostObj(getAccountUpdatedPostDetails(activePlatforms, postsDetails)));
      dispatch(addPostUpdateSavedPostDetails(getEditOrSchedulePost(activePlatforms, postsDetails, tzInfoName)));
      dispatch(addPostSetCustomTextEditorData(getPostContentTextField(activePlatforms, postsDetails)));
      dispatch(addPostGetSelectedFBTagList(isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) ? postsDetails?.page_tag_details?.facebook || [] : []));
      dispatch(addPostSetTags(getUpdatedTagsList(postsDetails.tags_list)));
      dispatch(addPostSetSelectedMediaList(getAddMediaListForCreator(postsDetails, accountId || 0)));
      dispatch(addPostSetLinkData(postsDetails?.url ? getCreatorLinkData(postsDetails) : null));
      if (postsDetails?.start_date) {
        dispatch(addPostEnablePostNow(!checkCurrentDateGreater(postsDetails?.start_date) ? false : true));
      }
      if (postsDetails?.use_instagram && postsDetails?.photo_urls?.length) {
        dispatch(
          addPostSetInstaPhotoPreviewObj({
            actionType: postsDetails?.pad_instagram_photos ? CreatorInstaPhotoType.PAD : CreatorInstaPhotoType.CROP,
            photoUrl: { originalUrls: [], previewUrls: [] }
          })
        );
      }
      if (campaigns?.length) {
        dispatch(addPostSetCampaignData(campaigns));
      }
      if (isEdit) handleNavigateToCreatorPage();
    }
  };

  const routePush = (path: string, searchParams: { [key: string]: string } = {}) => {
    navigate({ pathname: path, search: searchParams ? `?${new URLSearchParams(searchParams).toString()}` : `` });
  };

  const handleNavigateToCreatorPage = () => {
    routePush(ROUTES.CONTENT_CREATOR.replace(':type', userOwnership).replace(':id', id.toString()).replace('/*', ''));
  };

  const handleResubmitOrCancelApproval = (isResubmit?: boolean) => {
    if (postsDetails?.id) dispatch(postsUpdateSavedPostRequest({ id: String(postsDetails.id), status_id: isResubmit ? 2 : 1, ...(isResubmit && { rejected: false }) }));
  };

  const renderApproveSavedPost = () => {
    return postsDetails ? (
      <button className={`ac-btn ac-primary size-sm ${postsDetails.status_id === 3 ? `disabled` : ``}`} disabled={postsDetails.status_id === 3} onClick={() => handlePostApproveContent(postsDetails)}>
        {postsDetails.status_id === 3 ? `Approved` : `Approve`}
      </button>
    ) : null;
  };

  const EditOrScheduleButton = ({ tooltipText, buttonName, className = '' }: { tooltipText: string; buttonName: string; className?: string }) => {
    return (
      <CustomTooltip
        customPlacement={TOOLTIP_PLACEMENT.TOP}
        customTooltipText={tooltipText}
        customClassname={'custom-tooltip-long-text'}
        customInput={() => <button className={`ac-btn ac-primary size-sm button-opacity ${className}`}>{buttonName}</button>}
      />
    );
  };

  const handlePublishStatusModalClose = () => {
    const queryParamsObj = { ...queryParams };
    if (queryParamsObj.sort_by) {
      delete queryParamsObj.sort_by;
    }
    if (queryParamsObj.ascending) {
      delete queryParamsObj.ascending;
    }
    setIsPublishStatusModalOpen(false);
    navigate({ search: `?${new URLSearchParams(queryParamsObj).toString()}` });
  };

  return postsDetails ? (
    <>
      <div className={`btn-ac--wrp r-flx r-flx-je g-20`}>
        {/* Hub, location and Pending approval post */}
        {campaigns?.length ? (
          <CustomRippleButton rippleClass="csv-ripple d-none" custColor={RIPPLE_COLOR.primary}>
            <button className="ac-btn ac-primary ac-primary-white ac-outline size-sm" type="button" onClick={() => setIsRemovePostModal(true)}>
              Remove from campaign{campaigns.length > 1 ? 's' : ''}
            </button>
          </CustomRippleButton>
        ) : null}
        {/* Hub, location and Pending approval post */}
        {!postsRestriction.delete && (
          <CustomRippleButton rippleClass="danger-ripple" custColor={RIPPLE_COLOR.danger}>
            <button
              className="ac-btn ac-danger size-sm"
              onClick={() => {
                if (!activePlatforms.length) setConnectSocialPlatformModalOpen(true);
                else setShowDeleteModal(true);
              }}
            >
              {postsDetails?.status_id === 5 ? 'Reject' : 'Delete'}
            </button>
          </CustomRippleButton>
        )}

        {/* Pending approval post */}
        {userOwnership === USER_OWNERSHIP.FRANCHISOR && postsDetails?.account?.id && !postsRestriction.reject && (
          <CustomRippleButton rippleClass="danger-ripple" custColor={RIPPLE_COLOR.danger}>
            <button
              className={`ac-btn ac-danger size-sm h-default${isRejectPostFetching ? ` disabled` : ``}`}
              onClick={() => {
                if (postsDetails?.id) dispatch(rejectSavedPostFromHubRequest(postsDetails?.id));
              }}
            >
              {isRejectPostFetching ? <Spinner animation="border" /> : 'Reject'}
            </button>
          </CustomRippleButton>
        )}
        {/* Hub */}
        {userOwnership === USER_OWNERSHIP.FRANCHISOR && (postsDetails?.start_date !== postsSyndicateExpireDate?.start_date || postsDetails?.end_date !== postsSyndicateExpireDate?.end_date) && (
          <CustomRippleButton rippleClass="csv-ripple" custColor={RIPPLE_COLOR.primary}>
            <button className="ac-btn ac-primary  size-sm h-default r-flx r-flx-jc r-flx-ac" onClick={() => handleSaveExpireDate()}>
              Save
            </button>
          </CustomRippleButton>
        )}
        {/* Hub / Location both*/}
        {userOwnership === USER_OWNERSHIP.FRANCHISOR && campaignPost
          ? null
          : ((userOwnership === USER_OWNERSHIP.FRANCHISOR && !postsDetails?.account?.id && !postsDetails?.disabled) || (userOwnership === USER_OWNERSHIP.ACCOUNT && !postsDetails?.rejected)) &&
            !postsRestriction.copy && (
              <CustomRippleButton rippleClass={`csv-ripple ${editAccountDetail.isPostDetailLoading ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.primary}>
                <button
                  className={`ac-btn ac-primary  size-sm ${isCopyPostFetching ? `disabled` : ``}`}
                  type="button"
                  onClick={() => {
                    if (!activePlatforms.length) setConnectSocialPlatformModalOpen(true);
                    else handlePostsCopy(postsDetails.id);
                  }}
                >
                  Copy
                </button>
              </CustomRippleButton>
            )}
        {/* Hub */}
        {userOwnership === USER_OWNERSHIP.FRANCHISOR && canEdit(postsDetails, franchisorFullAccess) && !postsRestriction.edit && (
          <CustomRippleButton rippleClass="csv-ripple" custColor={RIPPLE_COLOR.primary}>
            <button className="ac-btn ac-primary  size-sm h-default r-flx r-flx-jc r-flx-ac" onClick={() => handleEditOrSchedulePost()}>
              Edit
            </button>
          </CustomRippleButton>
        )}
        {/* Pending approval post and Account */}
        {!postsRestriction.edit && ((userOwnership === USER_OWNERSHIP.FRANCHISOR && postsDetails?.account?.id) || userOwnership === USER_OWNERSHIP.ACCOUNT) ? (
          canScheduleAccountPost(postsDetails, isAdmin, isFranchisor, isFranchisee, overriddenCustomPostsLocked) ? (
            canBeScheduled(postsDetails, userOwnership) ? (
              <CustomRippleButton rippleClass={`csv-ripple ${editAccountDetail.isPostDetailLoading ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.primary}>
                <button
                  className={`ac-btn ac-primary  size-sm h-default r-flx r-flx-jc r-flx-ac ${editAccountDetail.isPostDetailLoading || (postsDetails?.end_date && isPostExpiry) ? ` disabled` : ``}`}
                  onClick={() => {
                    if (!activePlatforms.length) setConnectSocialPlatformModalOpen(true);
                    else handleEditOrSchedulePost();
                  }}
                >
                  {editAccountDetail.isPostDetailLoading ? <Spinner animation="border" /> : 'Edit'}
                </button>
              </CustomRippleButton>
            ) : (
              <EditOrScheduleButton tooltipText={POSTS_PACKAGE_EXPIRED_MSG} buttonName="Edit" className="r-ml2" />
            )
          ) : (
            <button className={`ac-btn ac-primary size-sm r-ml2`} onClick={() => (postsDetails?.status_id === 1 ? handleEditOrSchedulePost() : handleResubmitOrCancelApproval())}>
              {postsDetails?.status_id === 1 ? 'Edit Post' : 'Cancel Pending Approval'}
            </button>
          )
        ) : null}
        {/* Account */}
        {false && userOwnership === USER_OWNERSHIP.ACCOUNT && postsDetails?.rejected && postsDetails?.status_id === 1 ? (
          <CustomRippleButton rippleClass="danger-ripple" custColor={RIPPLE_COLOR.danger}>
            <button
              className="ac-btn ac-danger size-sm"
              onClick={() => {
                if (!activePlatforms.length) setConnectSocialPlatformModalOpen(true);
                else handleResubmitOrCancelApproval(true);
              }}
            >
              Resubmit
            </button>
          </CustomRippleButton>
        ) : null}
        {/* RAS-3745-Modified the functionality for location view post details edit and schedule seperate button  */}
        {userOwnership === USER_OWNERSHIP.ACCOUNT &&
        ![1, 2, 5].includes(postsDetails?.status_id) &&
        canScheduleAccountPost(postsDetails, isAdmin, isFranchisor, isFranchisee, overriddenCustomPostsLocked) &&
        !postsRestriction.schedule ? (
          canBeScheduled(postsDetails, userOwnership) ? (
            <CustomRippleButton rippleClass={`csv-ripple`} custColor={RIPPLE_COLOR.primary}>
              <button
                className="ac-btn ac-primary size-sm"
                onClick={() => {
                  if (!activePlatforms.length) setConnectSocialPlatformModalOpen(true);
                  else setIsSchedulePost(true);
                }}
              >
                Schedule
              </button>
            </CustomRippleButton>
          ) : (
            <EditOrScheduleButton tooltipText={POSTS_PACKAGE_EXPIRED_MSG} buttonName="Schedule" />
          )
        ) : null}
        {/* Hub */}
        {userOwnership === USER_OWNERSHIP.FRANCHISOR && campaignPost ? null : canApprove(postsDetails, franchisorFullAccess) && !postsRestriction.schedule && !isContentSupplier ? (
          ![1, 2, 5].includes(postsDetails?.status_id) && atLeastApproved(postsDetails?.status_id) && !postsDetails?.auto_schedule ? (
            canBeScheduled(postsDetails, userOwnership) ? (
              <CustomRippleButton rippleClass={`csv-ripple ${editAccountDetail.isPostDetailLoading ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.primary}>
                <button className="ac-btn ac-primary size-sm" onClick={() => handleContentSchedulePost()}>
                  Schedule
                </button>
              </CustomRippleButton>
            ) : (
              <EditOrScheduleButton tooltipText={POSTS_PACKAGE_EXPIRED_MSG} buttonName="Schedule" />
            )
          ) : postsDetails?.auto_schedule ? (
            <EditOrScheduleButton tooltipText={AUTO_SCHEDULE_DISABLE_PACKAGE} buttonName="Schedule" />
          ) : (
            <EditOrScheduleButton tooltipText={MANUALLY_SCHEDULE_DISABLE_PACKAGE} buttonName="Schedule" />
          )
        ) : null}
        {franchisorSyndication?.length && !postsRestriction.syndicate && !postsDetails?.account?.name ? (
          !isPostExpiry ? (
            <CustomRippleButton rippleClass={`csv-ripple ${editAccountDetail.isPostDetailLoading || postsDetails?.status_id === 1 ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.primary}>
              <button
                className={`ac-btn ac-primary  size-sm ${isCopyPostFetching ? `disabled` : ``}`}
                type="button"
                onClick={() => {
                  const fromDate = getUTCDateForPosts(convertStringToDate(postsDetails?.start_date), 0, 0, 0).selectedDate;
                  const toDate = getUTCDateForPosts(convertStringToDate(postsDetails?.end_date), 23, 59, 59).selectedDate;
                  setCreatorModalName(AP_FRAN_MODAL_NAMES.SYNDICATE);
                  setIsSyndicateModalOpen(true);
                  if (postsDetails) dispatch(addPostUpdateCustomTextEditor({ key: 'facebook', value: convertStringToEditorState(postsDetails[`facebook_text`] || '') }));
                  if (postsDetails?.start_date) {
                    dispatch(
                      addPostGetSelectedContentObj({
                        ...contentObj,
                        customDate: {
                          ...contentObj.customDate,
                          fromDate: { selected: fromDate, utcFormat: postsDetails?.start_date ? getUTCDateAndTimeForSelectedData(fromDate) : null },
                          toDate: { selected: toDate, utcFormat: postsDetails?.end_date ? getUTCDateAndTimeForSelectedData(toDate) : null }
                        },
                        ...(isContentSupplierFranchisor &&
                          postsDetails?.content_supplier?.is_content_supplier && {
                            franSyndicationMinMaxDate: {
                              minDate: fromDate,
                              maxDate: toDate
                            }
                          }),
                        ...(fromDate && toDate && { dateType: FranCreatorScheduleDateType.CUSTOM })
                      })
                    );
                  }
                }}
              >
                Syndicate
              </button>
            </CustomRippleButton>
          ) : (
            <EditOrScheduleButton tooltipText={POSTS_SYNDICATE_EXPIRED_MSG} buttonName="Syndicate" className="r-ml2" />
          )
        ) : null}
        {/* Pending approval post */}
        {userOwnership === USER_OWNERSHIP.FRANCHISOR &&
          !campaignPost &&
          (canApprove(postsDetails, franchisorFullAccess) || postsDetails?.account?.id) &&
          !postsRestriction.approve &&
          renderApproveSavedPost()}
        {userOwnership === USER_OWNERSHIP.ACCOUNT && postsDetails?.status_id === 5 && !postsRestriction.approve ? renderApproveSavedPost() : null}
      </div>
      <FranSyndicatePostModal
        isModalShow={isSyndicateModalOpen}
        handleModalClose={() => {
          setIsSyndicateModalOpen(false);
          dispatch(addPostUpdateCustomTextEditor({ key: 'facebook', value: EditorState.createEmpty() }));
        }}
        mediaType={FranchisorSyndicationMediaType.CONTENT}
        postOrMediaId={postsDetails.id}
      />
      <PostSyndicateContentModal isShowModal={syndicateContent.isShowModal} setSyndicateContent={setSyndicateContent} />
      <PostDeleteConfirmationModal isShowModal={isRemovePostModal} onModalClose={() => setIsRemovePostModal(false)} campaigns={campaigns} />
      {isPublishStatusModalOpen && <PostPublishStatusModal isShowModal={isPublishStatusModalOpen} onModalClose={handlePublishStatusModalClose} />}
      <ConnectSocialMediaModal isShowModal={isConnectSocialPlatformModalOpen} onModalClose={() => setConnectSocialPlatformModalOpen(false)} />
    </>
  ) : null;
};
