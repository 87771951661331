import { useCallback, useEffect, /* useEffect, */ useMemo, useState } from 'react';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Button, Spinner, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router';

import { ImageValidation } from 'widgets/Media';
import { IStore, ITableHeader, operationsListsApiTypes } from 'types';
import {
  accountCreateScheduledPostRequest,
  deleteLocationListRequest,
  subscriptionsLocationToListRequest,
  updateLocationsHubListRequest,
  updateLocationsHubListResponse,
  modifyLocationsHubListResponse,
  commonSetContentModifiedStatus
} from 'actions';
import { FEED_TYPE, LIST_HEADER, MODAL_BOX_TYPES, SUBSCRIBE_FEED_LIST_CONFIRMATION_MSG, USER_OWNERSHIP, RIPPLE_COLOR, CONNECTION_TABS } from 'utils/constants';
import { useAccountSwitcherData, useSSOIdentifiersForAccount } from 'utils/hooks';
import { utcToLocalHandler } from 'utils/helpers';
import { DeleteAssetModalPopup, ModalPopup, VirtualTable } from 'components';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

export const TMLists = () => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const navigate = useNavigate();
  const { id, userOwnership, currentRoute, optionalParams } = useAccountSwitcherData();
  const { isCSLocation } = useSSOIdentifiersForAccount();

  const locationsGroupList = useSelector((state: IStore) => state.operationsList.locationsGroupList);
  const isFetching = useSelector((state: IStore) => state.operationsList.isListFetching);
  const isTreeFetching = useSelector((state: IStore) => state.operationsList.isTreeFetching);
  const isDeleteRequest = useSelector((state: IStore) => state.operationsList.isDeleteRequest);
  const isSaveRequest = useSelector((state: IStore) => state.operationsList.isSaveRequest);
  const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);
  const modifyLocationGroups = useSelector((state: IStore) => state.operationsList.modifiedLocationGroupList);
  const isSSO = useSelector((state: IStore) => state.login.isSSO);

  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showAdditionalColumns, setShowAdditionalColumns] = useState(false);

  const isFromAccountWizard = optionalParams[0] === 'account_wizard' && optionalParams[2] === CONNECTION_TABS.FEED;

  useEffect(() => {
    dispatch(commonSetContentModifiedStatus(modifyLocationGroups.length ? true : false));
  }, [modifyLocationGroups, dispatch]);

  const locationsHubList = useMemo(() => {
    if (locationsGroupList.length > 0) {
      const publicLists = locationsGroupList.filter((location) => (userOwnership === USER_OWNERSHIP.ACCOUNT ? location.location_access : !location.locked));
      const privateLists = locationsGroupList.filter((location) => (userOwnership === USER_OWNERSHIP.ACCOUNT ? !location.location_access : location.locked));
      const filteredLocationsList = !showAdditionalColumns ? publicLists : publicLists.concat(privateLists);
      return filteredLocationsList.map((it) => {
        return { ...it, updated_at: moment(utcToLocalHandler(it.updated_at)).format('MMMM DD, YYYY @ hh:mm A') };
      });
    }
    return [];
  }, [locationsGroupList, showAdditionalColumns, userOwnership]);

  const handleSelectFeed = useCallback(
    (rowObj: operationsListsApiTypes.IDataResponse) => {
      const feedListIndex = modifyLocationGroups.findIndex((location) => location.id === rowObj.id);
      let updatedModifiedLocationGroups = [];

      if (feedListIndex > -1) {
        if (isFromAccountWizard && isSSO) {
          const updatedList = [...modifyLocationGroups];
          updatedList[feedListIndex] = { ...rowObj, account_included: !rowObj.account_included };
          updatedModifiedLocationGroups = updatedList;
        } else {
          updatedModifiedLocationGroups = modifyLocationGroups.filter((location) => location.id !== rowObj.id);
        }
      } else if (isFromAccountWizard && isSSO) {
        updatedModifiedLocationGroups = [...modifyLocationGroups, { ...rowObj, account_included: !rowObj.account_included }];
      } else {
        updatedModifiedLocationGroups = [...modifyLocationGroups, rowObj];
      }
      dispatch(modifyLocationsHubListResponse(updatedModifiedLocationGroups));
    },
    [isSSO, modifyLocationGroups, isFromAccountWizard, dispatch]
  );

  const renderHeader = useCallback(
    (headerObj: ITableHeader<operationsListsApiTypes.IDataResponse>) => {
      switch (headerObj.identifier) {
        case 'name':
          return <div>{isCSLocation ? 'Content Source' : headerObj.labelString}</div>;
        default:
          return <div>{headerObj.labelString}</div>;
      }
    },
    [isCSLocation]
  );

  const renderColumn = useCallback(
    (rowObj: operationsListsApiTypes.IDataResponse, header: ITableHeader<operationsListsApiTypes.IDataResponse>) => {
      switch (header.identifier) {
        case 'created_user':
          return <div>{rowObj[header.identifier]?.name}</div>;
        case 'feed_type':
          // return <div>{rowObj.feed_type === FEED_TYPE.STANDARD ? (rowObj.location_access ? 'Public Feed' : 'Private Account List') : 'Automated Content Scheduler'}</div>;
          return (
            <div className="slide-switch">
              <Form.Check
                type="switch"
                id="custom-switch-make-public"
                checked={rowObj.feed_type === FEED_TYPE.STANDARD ? false : true}
                onClick={(event) => event.stopPropagation()}
                onChange={(e: any) => {
                  dispatch(updateLocationsHubListRequest({ ...rowObj, feed_type: e.target.checked ? FEED_TYPE.CONTENT_SUPPLIER : FEED_TYPE.STANDARD }));
                }}
              />
            </div>
          );
        // case 'updated_at':
        //   return <div>{rowObj.updated_at}</div>;
        case 'make_public':
          return (
            <div className="slide-switch mpSwitch">
              <Form.Check
                type="switch"
                id="custom-switch-make-public"
                checked={Boolean(rowObj?.location_access)}
                onClick={(event) => event.stopPropagation()}
                onChange={() => {
                  dispatch(updateLocationsHubListRequest({ ...rowObj, location_access: !rowObj?.location_access }));
                }}
                className={Boolean(rowObj?.location_access) ? 'active' : ''}
              />
            </div>
          );
        // case 'content_supplier':
        //   // return <div>{rowObj.feed_type === FEED_TYPE.STANDARD ? (rowObj.location_access ? 'Public Feed' : 'Private Account List') : 'Automated Content Scheduler'}</div>;
        //   return (
        //     <div className="slide-switch">
        //       <Form.Check
        //         type="switch"
        //         id="custom-switch-make-public"
        //         checked={rowObj.feed_type === FEED_TYPE.STANDARD ? false : true}
        //         onClick={(event) => event.stopPropagation()}
        //         // onChange={(e: any) => {
        //         //   dispatch(updateLocationsHubListRequest({ ...rowObj, location_access: !rowObj?.location_access }));
        //         // }}
        //         disabled
        //       />
        //     </div>
        //   );
        case 'enroll':
          return (
            <div className="slide-switch">
              <Form.Check
                type="switch"
                id="custom-switch-make-public"
                checked={Boolean(rowObj?.account_included)}
                onClick={(event) => event.stopPropagation()}
                onChange={() => {
                  if (isFromAccountWizard && !isSSO) {
                    dispatch(subscriptionsLocationToListRequest({ account_id: id, account_list_id: rowObj?.id, account_included: !rowObj?.account_included, isFromAccountWizard }));
                  } else {
                    dispatch(updateLocationsHubListResponse({ ...rowObj, account_included: !rowObj?.account_included }));
                    handleSelectFeed(rowObj);
                  }
                }}
                className={Boolean(rowObj?.account_included) ? 'active' : ''}
              />
            </div>
          );
        case 'button':
          return !rowObj.locked ? (
            <Button className="plain-btn" variant="link">
              {deleteId === rowObj.id && isDeleteRequest ? (
                <Spinner animation="border" variant="danger" />
              ) : (
                <div className="rel-icons pb-icon">
                  <ImageValidation
                    isImgValid
                    defaultImg="delete-b-red"
                    customName="Delete"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setDeleteId(rowObj.id);
                      setShowModal(true);
                    }}
                  />
                </div>
              )}
            </Button>
          ) : (
            <></>
          );
        case 'name':
          return (
            <div className="lfsCreator">
              {userOwnership === USER_OWNERSHIP.FRANCHISOR ? <ImageValidation isImgValid defaultImg={!rowObj?.locked ? '' : 'lockGrey'} customName={'Location Access'} /> : null}
              <span className="lfsName"> {rowObj?.name}</span>
            </div>
          );
        case 'locations_count':
          return <div className="enrollCount">{rowObj[header.identifier]}</div>;
        default:
          return <div>{rowObj[header.identifier]}</div>;
      }
    },
    [id, isSSO, isDeleteRequest, deleteId, dispatch, handleSelectFeed, isFromAccountWizard, userOwnership]
  );

  const tableHeader = useMemo(() => {
    const filteredHeader = LIST_HEADER.filter((it) => {
      return it.identifier === 'feed_type'
        ? isContentSupplierFranchisor && userOwnership === USER_OWNERSHIP.FRANCHISOR
          ? true
          : false
        : userOwnership === USER_OWNERSHIP.FRANCHISOR
        ? it.identifier !== 'enroll'
        : it.identifier === 'name' || it.identifier === 'enroll';
    });
    return filteredHeader.map(
      (it): ITableHeader<operationsListsApiTypes.IDataResponse> => ({
        identifier: `${it.identifier}`,
        labelString: `${it.labelString}`,
        headerClassName: `${it.headerClassName}`,
        label: <div>{it.labelString}</div>,
        renderHeader,
        renderColumn
      })
    );
  }, [renderColumn, renderHeader, userOwnership, isContentSupplierFranchisor]);

  const handleSaveModalClose = () => {
    setShowSaveModal(false);
  };

  const saveFeedList = (isFreshStart: boolean = false) => {
    modifyLocationGroups.forEach((feed) => {
      dispatch(subscriptionsLocationToListRequest({ account_id: id, account_list_id: feed?.id, account_included: !feed?.account_included }));
    });
    if (isFreshStart) {
      dispatch(accountCreateScheduledPostRequest({ userOwnership, id, params: { delete_current_posts: 1 } }));
    }
    dispatch(modifyLocationsHubListResponse([]));
    handleSaveModalClose();
  };
  const revertFeedList = () => {
    if (modifyLocationGroups.length) {
      modifyLocationGroups.forEach((feed) => {
        dispatch(updateLocationsHubListResponse({ ...feed, account_included: feed?.account_included }));
      });
      dispatch(modifyLocationsHubListResponse([]));
    }
    handleSaveModalClose();
  };

  return (
    <div className={`tml-main feed-table tml-list lpx pt-20 mbl-head--margin cfTableData ${locationsHubList.length === 0 ? 'csEdit' : 'resuseTable'}`}>
      <VirtualTable<operationsListsApiTypes.IDataResponse>
        data={[locationsHubList]}
        headers={tableHeader}
        tableClassName="responsiveTable table-cmn--skeleton"
        ipadBoundary={767}
        ipadColCount={2}
        mobileColCount={2}
        pinColumnsRight={[4]}
        isFetching={isFetching || isTreeFetching || isSaveRequest}
        onRowClick={(row) => {
          if (row && !row.locked && userOwnership === USER_OWNERSHIP.FRANCHISOR) {
            navigate({ pathname: `/${userOwnership}/${id}/${currentRoute}/${row.id}/step-1` });
          }
        }}
        scrollableTargetString={optionalParams[2] === CONNECTION_TABS.FEED ? 'scrollTargetAccountWizard' : 'scrollTarget'}
        additionalColumnTitle={`${showAdditionalColumns ? 'Hide' : 'Show'} ${userOwnership === USER_OWNERSHIP.FRANCHISOR ? 'Content Supplier(s)' : 'Other Content Supplier(s)'}`}
        hasAdditionalColumns={Boolean(
          locationsGroupList.some((location) => (userOwnership === USER_OWNERSHIP.ACCOUNT && !location.location_access) || (userOwnership === USER_OWNERSHIP.FRANCHISOR && location.locked))
        )}
        handleShowAdditionalColumns={() => {
          setShowAdditionalColumns((prev) => !prev);
        }}
      />

      {!isFromAccountWizard && modifyLocationGroups?.length ? (
        <div className="stg-item form-configure r-mt4">
          <div className="fc-actions">
            <CustomRippleButton rippleClass="ripple-unset ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
              <button className={`ac-btn ac-secondary-white border-0 ac-outline size-sm`} onClick={() => revertFeedList()}>
                Cancel
              </button>
            </CustomRippleButton>
            <CustomRippleButton rippleClass="ripple-unset ac-primary-box" custColor={RIPPLE_COLOR.primary}>
              <button className={`ac-btn ac-primary size-sm`} type="submit" onClick={() => setShowSaveModal(true)}>
                Save
              </button>
            </CustomRippleButton>
          </div>
        </div>
      ) : null}

      <DeleteAssetModalPopup
        showModal={showModal}
        handleModalClose={() => {
          setShowModal(false);
        }}
        handleDelete={() => {
          if (deleteId) {
            dispatch(deleteLocationListRequest({ id: deleteId }));
            setShowModal(false);
          }
        }}
      />
      <ModalPopup
        isModalShow={showSaveModal}
        containerClassName={`modal-confirmation confirm-delete ${showSaveModal ? `feed-ssm-on` : `feed-ssm-off`}`}
        modalHeaderType={MODAL_BOX_TYPES.INFO}
        modalBody={() => (
          <>
            <div className="edit-approval-post-wrp-modal">
              <div className="modal-message-wraps-text">{SUBSCRIBE_FEED_LIST_CONFIRMATION_MSG}</div>
            </div>
          </>
        )}
        modalCloseButton
        handleModalClose={handleSaveModalClose}
        isModalFooterShow
        modalFooter={() => {
          return (
            <div className="modal-btn-grp-wraps">
              <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.secondary}>
                <button className={`ac-btn ac-secondary ac-block modal-btn-action-itm modal-ok-btn fssm-sb`} onClick={() => saveFeedList()}>
                  <span>Apply changes in 30 days</span>
                </button>
              </CustomRippleButton>

              <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                <button className={`ac-btn ac-primary ac-block modal-btn-action-itm modal-ok-btn fssm-pb`} onClick={() => saveFeedList(true)}>
                  <span>Start a fresh calendar</span>
                </button>
              </CustomRippleButton>

              <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.whiteGrey}>
                <button className={`ac-btn ac-primary-white ac-outline modal-btn-action-itm modal-ok-btn fssm-cancel`} onClick={() => revertFeedList()}>
                  <span>Cancel</span>
                </button>
              </CustomRippleButton>
            </div>
          );
        }}
      />
    </div>
  );
};
