import { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { commonModalPopup, IStore } from 'types';
import { addCustomizeLink, addLocalizeLink, addPostAddLinkRequest, setCustomizeLinkEnable } from 'actions';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { ALERT_BOX_TYPES, RIPPLE_COLOR, ADD_POST_INVALID_LINK_MSG, USER_OWNERSHIP } from 'utils/constants';
import { convertValidUrlFromLinkText, isValidUrl } from 'utils/helpers';
import { ImageValidation } from 'widgets/Media';
import { alertBoxCall } from 'components';
import { AddCustomizeLink } from './AddCustomizeLink';
import { AddLocalizeLink } from './AddLocalizeLink';
import { useAccountSwitcherData } from 'utils/hooks';

export const AddLink = (props: commonModalPopup.ILinkModalPopup) => {
  const dispatch = useDispatch();

  const { userOwnership } = useAccountSwitcherData();

  const { isModalShow, handleModalClose } = props;

  const isLinkFetching = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.isLinkFetching);
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const isCustomizeLinkEnable = useSelector((state: IStore) => state.addPostFranchisorAction.isCustomizeLinkEnable);
  const isContentCustomizeLink = useSelector((state: IStore) => state.accountSwitcher?.content_customize_link || false);
  const isCustomizeLinkAdded = useSelector((state: IStore) => state.addPostFranchisorAction.isCustomizeLinkAdded || false);
  const isLocalizeLinkAdded = useSelector((state: IStore) => state.addPostFranchisorAction.isLocalizeLinkAdded || false);

  const [linkText, setLinkText] = useState(linkData?.url || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLocalizeSubmitting, setIsLocalizeSubmitting] = useState(false);
  const [isCustomizeLinkEnabled, setIsCustomizeLinkEnabled] = useState(isCustomizeLinkEnable || isCustomizeLinkAdded);

  const [isLocalizeLinkEnabled, setIsLocalizeLinkEnable] = useState(isLocalizeLinkAdded);

  const handleAddLink = () => {
    if (!isValidUrl(linkText)) {
      alertBoxCall(ALERT_BOX_TYPES.WARN, ADD_POST_INVALID_LINK_MSG);
    } else {
      dispatch(setCustomizeLinkEnable(false));
      dispatch(addCustomizeLink(false));
      dispatch(addLocalizeLink(false));
      dispatch(addPostAddLinkRequest(convertValidUrlFromLinkText(linkText)));
      handleModalClose();
    }
  };

  return (
    <>
      <Modal className="creator-addlink-modal-wrp alctaWrap caddLink" size="sm" aria-labelledby="contained-modal-title-vcenter" centered show={isModalShow} animation={false}>
        <div className="mod__close--icon">
          <ImageValidation defaultImg="close-modal-icon" customClassname="mod__close--img" customName={'close'} onClick={handleModalClose} />
        </div>
        <Modal.Body>
          <h3 className="add__link--title">Add Link</h3>
          <div className="filesUpload adlinkselected">
            <div className="fu-cnt">
              <div className="fuitem">
                <div className="fui-left">
                  <div className="alctTop">
                    <Form.Group className="form-group form-field-item">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Business Link"
                        value={linkText || ''}
                        // readOnly={linkData?.id ? true : false}
                        onChange={(event) => setLinkText(event.target.value)}
                      />
                    </Form.Group>

                    <div className={`form-check`}>
                      {userOwnership === USER_OWNERSHIP.FRANCHISOR ? (
                        <>
                          <label className="checkbox-item">
                            <span className="checkbox-hover">
                              <input
                                type="checkbox"
                                name="mobile_app_only_enabled"
                                className="option-input click-wave"
                                checked={isLocalizeLinkEnabled}
                                onChange={() => {
                                  setIsLocalizeLinkEnable((prev: boolean) => !prev);
                                  setIsCustomizeLinkEnabled(false);
                                  dispatch(addCustomizeLink(false));
                                }}
                              />
                            </span>
                            <div className="label-txt">Localize</div>
                            <span className="checkmark" />
                          </label>
                        </>
                      ) : null}

                      {isContentCustomizeLink ? (
                        <>
                          <label className="checkbox-item">
                            <span className="checkbox-hover">
                              <input
                                type="checkbox"
                                name="mobile_app_only_enabled"
                                className="option-input click-wave"
                                checked={isCustomizeLinkEnabled}
                                onChange={() => {
                                  setIsCustomizeLinkEnabled((prev: boolean) => !prev);
                                  setIsLocalizeLinkEnable(false);
                                  dispatch(addLocalizeLink(false));
                                }}
                              />
                            </span>
                            <div className="label-txt">Customize</div>
                            <span className="checkmark" />
                          </label>
                        </>
                      ) : null}
                    </div>
                  </div>

                  {isLocalizeLinkEnabled && (
                    <AddLocalizeLink linkText={linkText} handleModalClose={handleModalClose} isLocalizeSubmitting={isLocalizeSubmitting} setIsLocalizeSubmitting={setIsLocalizeSubmitting} />
                  )}

                  {isCustomizeLinkEnabled && isContentCustomizeLink && (
                    <AddCustomizeLink linkText={linkText} handleModalClose={handleModalClose} isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} />
                  )}
                </div>

                <div className="btn-wrp-separate">
                  {/* {!linkData ? ( */}
                  <CustomRippleButton rippleClass={`ac-primary-box${!linkText ? ` pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
                    <button
                      type="button"
                      className={'ac-btn ac-primary ac-block'}
                      onClick={() => (!isCustomizeLinkEnabled && !isLocalizeLinkEnabled ? handleAddLink() : isCustomizeLinkEnabled ? setIsSubmitting(true) : setIsLocalizeSubmitting(true))}
                    >
                      {!isLinkFetching ? `Done` : `Generating...`}
                    </button>
                  </CustomRippleButton>
                  {/* ) : ( */}
                  {/* <CustomRippleButton rippleClass={`ac-danger-box${!linkText ? ` pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.danger}>
                      <button type="button" className={'ac-btn ac-danger ac-block'} onClick={handleDeleteLink}>
                        Delete link
                      </button>
                    </CustomRippleButton> */}
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
