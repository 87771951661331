import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';

import { commonModalPopup, IStore } from 'types';
import { ADD_POST_INVALID_LINK_MSG, ALERT_BOX_TYPES, AP_FRAN_LOCALIZED_PREVIEW_LINK_OPTION_DATA, AP_FRAN_LOCALIZED_PREVIEW_LINK_TYPE } from 'utils/constants';
import { isValidUrl } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { CustomDropdown } from 'widgets/CustomDropdown';
import { addLocalizeLink, addLocalizeLinkURL, addPostAddLinkRequest, addPostLocalizePreviewsLinkRequest, addPostLocalizePreviewsLinkResponse } from 'actions';
import { alertBoxCall } from 'components';

export const AddLocalizeLink = ({ linkText, handleModalClose, isLocalizeSubmitting, setIsLocalizeSubmitting }: commonModalPopup.ILocalizeLinkProps) => {
  const dispatch = useDispatch();

  const { id } = useAccountSwitcherData();

  const linkObj = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj);
  const localizePreviewLinks = useSelector((state: IStore) => state.addPostFranchisorAction.localizePreviewLinks);
  const localizeLinkURL = useSelector((state: IStore) => state.addPostFranchisorAction.localizeLinkURL);

  const [localizeLinkData, setLocalizeLinkData] = useState(AP_FRAN_LOCALIZED_PREVIEW_LINK_TYPE);

  const { values, handleSubmit, setFieldValue, handleBlur } = useFormik({
    initialValues: {
      url: linkText || linkObj?.linkData?.url || '',
      localize_url: localizeLinkURL || ''
    },
    enableReinitialize: true,
    onSubmit: () => {
      dispatch(
        addPostLocalizePreviewsLinkRequest({
          franchisor_id: id,
          url: values.url,
          personalized_url: values.localize_url
        })
      );
    }
  });

  const dropdownOption = useMemo(() => {
    return AP_FRAN_LOCALIZED_PREVIEW_LINK_OPTION_DATA;
  }, []);

  useEffect(() => {
    if (localizePreviewLinks) {
      dispatch(addPostAddLinkRequest({ url: localizePreviewLinks.url, personalized_url: values.localize_url }));
      dispatch(addLocalizeLink(true));
      if (isLocalizeSubmitting) {
        dispatch(addPostLocalizePreviewsLinkResponse(null));
        dispatch(addLocalizeLinkURL(null));
        handleModalClose();
      }
    }
  }, [localizePreviewLinks, dispatch]); // eslint-disable-line

  useEffect(() => {
    if (linkText) {
      setFieldValue('localize_url', `${linkText}${localizeLinkURL ? `/${localizeLinkURL}` : ''}`);
      const localizeObj = localizeLinkURL ? dropdownOption.find((item) => localizeLinkURL.includes(item.value)) : '';
      if (localizeObj) setLocalizeLinkData(localizeObj);
    }
  }, [linkText, localizeLinkURL, dropdownOption, setFieldValue]);

  useEffect(() => {
    if (isLocalizeSubmitting) {
      if (isValidUrl(linkText)) {
        handleSubmit();
      } else {
        alertBoxCall(ALERT_BOX_TYPES.WARN, ADD_POST_INVALID_LINK_MSG);
        setIsLocalizeSubmitting(false);
      }
    }
  }, [isLocalizeSubmitting]); // eslint-disable-line

  return (
    <>
      <div className="alc-item">
        <div className={`form-group form-field-item s fg-dropdown m-align-items cs-item cs-clean s-mb2`}>
          <CustomDropdown
            classNamePrefix="cs-select"
            id="localize-preview-link-fields"
            placeholder={'Select a field for localization'}
            onChange={(event: any) => {
              dispatch(addLocalizeLinkURL(event.value));
              setLocalizeLinkData({ id: event.id, value: event.value, label: event.label, disabled: false });
            }}
            value={localizeLinkData?.id ? localizeLinkData : null}
            options={dropdownOption}
          />
        </div>
        <div className="alc-item">
          <Form.Group className="form-group form-field-item">
            <input
              type="text"
              className="form-control"
              placeholder="Localization Link"
              value={values.localize_url as string}
              onChange={(event) => setFieldValue('localize_url', event.target.value)}
              onBlur={handleBlur}
            />
          </Form.Group>
        </div>
      </div>
    </>
  );
};
